import { useDispatch } from "react-redux";
import { values } from "lodash";
import { useAppSelector } from "../../../../../../effects/useAppSelector";
import { UnsubscribeAllButton } from "../UnsubscribeAllButton";
import { manageEmailPreferencesActions } from "../../../../../../actions/manage-email-preferences.actions";
import { AlertEnabledState } from "../../../../../../types/enums/AlertEnabledState";
import { AlertOption } from "../../../../../../types/email-preferences/EmailPreferences";

interface Props {
    disabled?: boolean;
}

export function UnsubscribePortfolioAlertsButton({ disabled }: Props) {
    const dispatch = useDispatch();

    const bwicAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.portfolioBwicAlertState);
    const invneotryAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.portfolioDealersInventoryAlertState);
    const filtersAlertState = useAppSelector(s => s.manageEmailPreferences.portfolioSavedFilterAlerts);

    const hasEnabledAlerts = () =>
        bwicAlertState === AlertEnabledState.Enabled ||
        bwicAlertState === AlertEnabledState.PartiallyEnabled ||
        invneotryAlertState === AlertEnabledState.Enabled ||
        invneotryAlertState === AlertEnabledState.PartiallyEnabled ||
        values(filtersAlertState).some(x =>
            x.bwicAlert === AlertOption.Instant ||
            x.inventoryAlert === AlertOption.Instant
        );

    return (
        <UnsubscribeAllButton
            onClick={() => dispatch(manageEmailPreferencesActions.unsubscribeAllPortfolio())}
            disabled={disabled || !hasEnabledAlerts()}
        />
    );
}