import { useState } from "react";
import { AlertOption } from "../../../../../../types/email-preferences/EmailPreferences";
import { Portfolio } from "../../../../../../types/portfolio/Portfolio";
import { Popup, PopupBody, PopupFooter } from "../../../../../controls";
import { EmailPreferencesRadioGroup } from "../EmailPreferencesRadioGroup";
import { portfolioActions } from "../../../../../../actions/portfolio.actions";
import { useAppDispatch } from "../../../../../../effects/useAppDispatch";
import { textLabels } from "../../../../../../constants/text-labels";
import { Link } from "../../../../../controls/Link";
import { routes } from "../../../../../../constants";
import { EmailPreferencesTab } from "../../EmailPreferencesContent";
import { SubscriptionFeature } from '../../../../../../types/billing/SubscriptionFeature';

interface Props {
    portfolio: Portfolio;
    onClose: () => void;
}

export function SinglePortfolioAlertsPopup({ portfolio, onClose }: Props) {
    const dispatch = useAppDispatch();

    const [bwicAlert, setBwicAlert] = useState(portfolio.bwicAlert);
    const [inventoryAlert, setInventoryAlert] = useState(portfolio.dealersInventoryAlert);
    const [issuanceMonitorAlert, setIssuanceMonitorAlert] = useState(
        portfolio.issuanceMonitorAlert,
    );
    const [rollerDeadlineAlert, setRollerDeadlineAlert] = useState(portfolio.rollerDeadlineAlert);

    const hasChanges =
        bwicAlert !== portfolio.bwicAlert ||
        inventoryAlert !== portfolio.dealersInventoryAlert ||
        issuanceMonitorAlert !== portfolio.issuanceMonitorAlert ||
        rollerDeadlineAlert !== portfolio.rollerDeadlineAlert;

    const handleSave = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();

        if (!hasChanges) {
            return;
        }

        dispatch(
            portfolioActions.updatePortfoliosAlertStatus([
                {
                    portfolioId: portfolio.id,
                    bwicAlert: bwicAlert === portfolio.bwicAlert ? undefined : bwicAlert,
                    dealersInventoryAlert:
                        inventoryAlert === portfolio.dealersInventoryAlert ? undefined : inventoryAlert,
                    issuanceMonitorAlert: issuanceMonitorAlert === portfolio.issuanceMonitorAlert ? undefined : issuanceMonitorAlert,
                    rollerDeadlineAlert: rollerDeadlineAlert === portfolio.rollerDeadlineAlert ? undefined : rollerDeadlineAlert,
                },
            ]),
        );

        onClose();
    };

    const handleClose = (e?: React.MouseEvent<HTMLButtonElement> | MouseEvent) => {
        e?.stopPropagation();
        onClose();
    };

    return (
        <Popup
            modalClass="modal-email-preferences"
            renderInBody
            title={
                <>
                    Portfolio Alerts <span className="name-tag">{portfolio.name}</span>
                </>
            }
            onClose={handleClose}
        >
            <PopupBody className="notifications-center">
                <EmailPreferencesRadioGroup
                    value={bwicAlert ? AlertOption.Instant : AlertOption.Never}
                    alertOptions={[AlertOption.Never, AlertOption.Instant]}
                    alertOptionInfo={{
                        [AlertOption.Instant]: textLabels.savedFiltersPortfolioBwicInstantAlert,
                    }}
                    groupNameIndentifier="single-portfolio-bwic-alert-state"
                    title="BWIC Alerts"
                    description={textLabels.savedFiltersPortfolioBwicAlertDescription}
                    onChange={o => setBwicAlert(o === AlertOption.Instant)}
                />
                <EmailPreferencesRadioGroup
                    value={inventoryAlert ? AlertOption.Instant : AlertOption.Never}
                    alertOptions={[AlertOption.Never, AlertOption.Instant]}
                    alertOptionInfo={{
                        [AlertOption.Instant]: textLabels.saveFiltersPortfolioInventoryInstantsAlert,
                    }}
                    groupNameIndentifier="single-portfolio-inventory-alert-state"
                    title="Inventory Alerts"
                    description={textLabels.savedFiltersPortfolioInventoryAlertDescription}
                    onChange={o => setInventoryAlert(o === AlertOption.Instant)}
                />
                <EmailPreferencesRadioGroup
                    value={issuanceMonitorAlert ? AlertOption.Instant : AlertOption.Never}
                    alertOptions={[AlertOption.Never, AlertOption.Instant]}
                    alertOptionInfo={{
                        [AlertOption.Instant]: textLabels.savedFiltersPortfolioIssuanceMonitorInstantAlert,
                    }}
                    groupNameIndentifier="single-portfolio-issuance-monitor-alert-state"
                    title="Issuance Monitor"
                    description={textLabels.savedFiltersPortfolioIssuanceMonitorAlertDescription}
                    onChange={(o) => setIssuanceMonitorAlert(o === AlertOption.Instant)}
                    requiredFeatures={[
                        SubscriptionFeature.IssuanceMonitorFullAccess,
                        SubscriptionFeature.PortfolioAlerts,
                    ]}
                />
                <EmailPreferencesRadioGroup
                    value={rollerDeadlineAlert ? AlertOption.Daily : AlertOption.Never}
                    alertOptions={[AlertOption.Never, AlertOption.Daily]}
                    alertOptionInfo={{
                        [AlertOption.Daily]: textLabels.savedFiltersPortfolioRollerDeadlineDailyAlert,
                    }}
                    groupNameIndentifier="single-portfolio-roller-deadline-alert-state"
                    title="Roller Deadline"
                    description={textLabels.savedFiltersPortfolioRollerDeadlineAlertDescription}
                    onChange={(o) => setRollerDeadlineAlert(o === AlertOption.Daily)}
                    requiredFeatures={[
                        SubscriptionFeature.IssuanceMonitorFullAccess,
                        SubscriptionFeature.PortfolioAlerts,
                    ]}
                />
            </PopupBody>

            <PopupFooter>
                <Link to={routes.profileNotificationsTab(EmailPreferencesTab.Portfolio)} className="btn-link margin-r-a">
                    Manage Alerts
                </Link>
                <button className="btn btn-ghost" onClick={handleClose}>
                    Cancel
                </button>
                <button className="btn btn-main" onClick={handleSave} disabled={!hasChanges}>
                    Save
                </button>
            </PopupFooter>
        </Popup>
    );
}
