import { isEqual } from 'lodash';
import { useDispatch } from "react-redux";
import { useHistory } from 'react-router';
import { manageEmailPreferencesActions } from "../../../../../../actions/manage-email-preferences.actions";
import { useAppSelector } from '../../../../../../effects/useAppSelector';
import { AlertOption } from "../../../../../../types/email-preferences/EmailPreferences";
import { StatusMessageSectionType } from "../../../../../../types/state/NotificationState";
import { isRequesting } from "../../../../../../utils/request-state.utils";
import { RouteLeavingGuard } from '../../../../../routing';
import { StatusMessageSection } from "../../../../../status-message/StatusMessageSection";
import { EmailPreferencesByRating } from "../EmailPreferencesByRating";
import { EmailPreferencesRadioGroup } from "../EmailPreferencesRadioGroup"
import { SubscriptionFeature } from '../../../../../../types/billing/SubscriptionFeature';

interface BwicMonitorAlertsTableProps {
    collapsible?: boolean;
}

export const BwicMonitorAlertsTable = ({ collapsible = false }: BwicMonitorAlertsTableProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const bwicByRatingAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.bwicByRatingAlertState);
    const initialPreferences = useAppSelector(s => s.manageEmailPreferences.initialPreferences.bwicByRatingAlertState);
    const isRequestingSavePreferences = useAppSelector(s => isRequesting(s.manageEmailPreferences.requestStateSaveEditPreferences));

    const ratingPreference = useAppSelector(s => s.manageEmailPreferences.editPreferences.ratingPreference);
    const initialRatingPreference = useAppSelector(s => s.manageEmailPreferences.initialPreferences.ratingPreference);

    const bwicByRatingAlertStatePartiallySelected =
        ratingPreference.some(({ isUSD, isEUR }) => isUSD || isEUR) &&
        ratingPreference.some(({ isUSD, isEUR }) => !isUSD || !isEUR)

    const isBwicByRatingAlertStateAtleastOneSelected = useAppSelector(s => {
        const preferences = s.manageEmailPreferences.editPreferences.ratingPreference;
        return preferences.some(({ isUSD, isEUR }) => isUSD || isEUR);
    });

    const hasChanges = !(isEqual(bwicByRatingAlertState, initialPreferences) && isEqual(ratingPreference, initialRatingPreference))

    return (
        <EmailPreferencesRadioGroup
            value={bwicByRatingAlertState}
            disabled={isRequestingSavePreferences}
            alertOptions={[AlertOption.Never, AlertOption.Daily, AlertOption.Instant]}
            alertOptionInfo={{
                [AlertOption.Daily]: 'Alerts are sent at 7 AM EST every business day excluding holidays',
                [AlertOption.Instant]: 'Alerts are sent once the BWIC with your desired preferences is posted'
            }}
            groupNameIndentifier="bwic-by-rating-alert-state"
            title="BWICs by Rating"
            description="Receive a daily email (at 7 AM EST every business day excluding holidays) or whenever a BWIC with your desired tranches is posted."
            onChange={alertOption => dispatch(manageEmailPreferencesActions.setBwicByRatingPreferences(alertOption))}
            partially={bwicByRatingAlertStatePartiallySelected}
            requiredFeature={SubscriptionFeature.BwicMonitorAlerts}
            collapsible={collapsible}
        >
            {!isBwicByRatingAlertStateAtleastOneSelected && bwicByRatingAlertState !== AlertOption.Never ?
                <StatusMessageSection type={StatusMessageSectionType.Info}>
                    Cannot be saved without selecting at least one option below
                </StatusMessageSection>
                : null}
            <EmailPreferencesByRating />
            <RouteLeavingGuard
                navigate={(pathname: string) => {
                    dispatch(manageEmailPreferencesActions.rollbackBWICPipelines())
                    history.push(pathname);
                }}
                shouldBlockNavigation={() => hasChanges}
            />
        </EmailPreferencesRadioGroup>
    )
}
