import { useMemo } from "react";
import { isEqual } from "lodash";
import cn from 'classnames';
import { isRequesting } from "../../../../../../utils/request-state.utils";
import { constants } from "../../../../../../constants";
import { AlertOption } from "../../../../../../types/email-preferences/EmailPreferences";
import { useAppSelector } from "../../../../../../effects/useAppSelector";
import { UserConfigFilter } from "../../../../../../types/user-config/UserConfigFilter";

interface Props {
    className?: string;
    currentFilters?: UserConfigFilter[];
    disabled?: boolean;
    onSave: (updatedFilters?: UserConfigFilter[]) => void;
}

export const BwicMonitorAlertsSaveButton = ({ className, currentFilters, disabled, onSave }: Props) => {
    const initialPreferences = useAppSelector(s => s.manageEmailPreferences.initialPreferences);
    const editPreferences = useAppSelector(s => s.manageEmailPreferences.editPreferences);
    const savePreferencesRequestState = useAppSelector(s => s.manageEmailPreferences.requestStateSaveEditPreferences);
    const emailPreferencesRequestState = useAppSelector(s => s.manageEmailPreferences.requestStateGetPreferences);
    const bwicByRatingAlertState = useAppSelector(s => s.manageEmailPreferences.editPreferences.bwicByRatingAlertState);
    const savedFiltersEditState = useAppSelector(s => s.manageEmailPreferences.editSavedFiltersPreferences);
    
    const isFilterAlertsChanged = currentFilters?.some(f =>
        savedFiltersEditState[f.referenceName] != null &&
        savedFiltersEditState[f.referenceName] !== f.alertOption
    );

    const hasChanges = isFilterAlertsChanged || !isEqual(initialPreferences, editPreferences);

    const isSaveButtonDisabled = useMemo(() => {
        const isBwicByRatingAlertStateAtleastOneSelected = editPreferences
            .ratingPreference.some(({ isUSD, isEUR }) => isUSD || isEUR);
        const someRatingError = editPreferences.ratingPreference
            .some(({ isUSD, isEUR, minSize, maxSize }) =>
                (isUSD || isEUR) &&
                (minSize < constants.emailPreferencesSizeRange.min || maxSize > constants.emailPreferencesSizeRange.max || maxSize < minSize)
            );
        return !(!someRatingError && hasChanges) || (!isBwicByRatingAlertStateAtleastOneSelected && bwicByRatingAlertState !== AlertOption.Never);
    }, [editPreferences, hasChanges, bwicByRatingAlertState]);

    const isDisabled = disabled
        || isSaveButtonDisabled
        || isRequesting(emailPreferencesRequestState, savePreferencesRequestState);

    const handleSave = () => {
        let updatedFilters: UserConfigFilter[] | undefined;
        if (isFilterAlertsChanged && currentFilters?.length) {
            updatedFilters = currentFilters.map(f => ({
                ...f,
                alertOption: savedFiltersEditState[f.referenceName] ?? f.alertOption
            }));

        }

        onSave(updatedFilters);
    }

    return (
        <button
            className={cn("btn btn-main", className)}
            disabled={isDisabled}
            onClick={handleSave}
        >
            Save
        </button>
    );
}